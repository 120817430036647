import "./App.css";
import React, { Component } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import ScrollContainer from "react-indiana-drag-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NameType from "./images/rebus-logo.png";
import yellowjackets from "./images/wasp.gif";
import toms from "./images/toms.png";
import bmw from "./images/bmw.png";
import marn from "./images/marn.png";
import ExternalLink from "./images/external-link.svg";

var contentful = require("contentful");

var client = contentful.createClient({
  space: "utcdy24ogzj9",
  accessToken:
    "10348d9a96195088356b39cef368c63c613756f85c28e45a074a511ba84536fe",
});

const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
    font-family: "RT Dromo Book";
  }
  html, body {
    overscroll-behavior-x: none;
  }
  .padded {
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: 500px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .reverse {
    @media (max-width: 992px) {
      flex-direction: column-reverse !important;
    }
  }
  .carousel {
    margin-top: 16px;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 0 16px 24px 24px;

    @media (max-width: 500px) {
      padding: 0 0 0 8px;
    }
    & > div{
      margin-right: 16px;
      @media (max-width: 500px) {
        margin-right: 8px;
      }
    }

    &:hover{
      cursor: drag !important;
    }

    &::-webkit-scrollbar {
      height: 8px;
      border-radius: 44px;
    }

    &::-webkit-scrollbar-track {
      background: #17191C;
      border-radius: 44px;
      margin-left: 25vw;
      margin-right: 25vw;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #E7E7F2; 
      border-radius: 44px;
    }
    
  }

  .link{
    flex: 0 0 auto;
    padding: 16px;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #A1A2A4;
    transition: all ease 0.5s;
    &:hover{
      transition: all ease 0.5s;
      cursor: pointer;
      color: #E7E7F2;
    }
    @media (max-width: 500px){
      padding: 8px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .active {
    transition: all ease 0.5s;
    color: #E7E7F2;
    &:hover{
      cursor: pointer;
    }
  }

  .itemPic {
    width: 100%;
    overflow: hidden;
    min-height: 31vw;
    margin-top: 16px;
    border-radius: 0 0 16px 16px;
    @media (max-width: 1000px) {
      min-height: 45vw;
    }
    @media (max-width: 750px) {
      min-height: 80vw;
    }
    @media (max-width: 500px) {
      min-height: 88vw;
      margin-top: 8px;
    }
  }

  .heroPic {
    width: 100%;
    @media (max-width: 500px) {
      margin-bottom: 24px;
    }
  }

  .lazy{
    border-radius: 16px 16px 0 0 !important;
    overflow: hidden;
  }

  .scrollParentFix {
    padding-right: 24px !important;
  }
`;

const Spacer = styled.div`
  width: 24px;
  color: black;
  height: 100% !important;
  @media (max-width: 500px) {
    width: 16px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  @media (max-width: 500px) {
    padding-bottom: 0px;
  }
`;

const Name = styled.img`
  width: 100%;
  @media (max-width: 992px) {
    margin-top: 24px;
  }
`;

const Links = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 992px) {
    justify-content: flex-start;
  }
  li a {
    text-decoration: none;
    font-size: 24px;
    line-height: 32px;
    color: #e7e7f2;
    padding: 0 10px;
    font-family: "RT Dromo Book";
    display: flex;
    @media (max-width: 500px) {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 0px;
    }
    @media (max-width: 992px) {
      padding: 0 10px 0 0;
      float: left;
    }
  }
`;

const About = styled.div`
  font-size: 52px;
  line-height: 56px;
  font-family: "RT Dromo Book";
  @media (max-width: 500px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

const Icon = styled.img`
  margin-left: 10px;
`;

const Rebus = styled.div`
  color: #e7e7f2;
  display: inline;
  position: relative;
  &::before {
    vertical-align: middle;
    z-index: 999;
    position: relative;
    display: inline-block;
    height: 52px;
    margin-right: 4px;
    width: 52px;
    background-image: url(${(props) => props.imgSrc});
    background-size: cover;
    bacgkround-position: center center;
    content: "";
    @media (max-width: 500px) {
      height: 30px;
      width: 30px;
    }
  }
`;

const Projects = styled.div`
  padding: 120px 0 0 0;
`;

const Project = styled.div`
  padding: 0 0 120px 0;
  @media (max-width: 500px) {
    padding: 0 0 80px 0;
    &:last-child {
      padding: 0 0 120px 0;
    }
  }
`;

const Title = styled.div`
  font-size: 80px;
  line-height: 88px;
  display: flex;
  align-items: top;
  @media (max-width: 500px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Duration = styled.span`
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Description = styled.div`
  font-size: 30px;
  line-height: 40px;
  color: #a2a2b0;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Item = styled.div`
  width: 31vw;
  flex: 0 0 auto;
  border: 1px solid #2a2a2a;
  overflow: hidden !important;
  border-radius: 16px;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all ease 0.5s;
  @media (max-width: 1000px) {
    width: 45vw;
  }
  @media (max-width: 750px) {
    width: 80vw;
  }
  @media (max-width: 500px) {
    width: 88vw;
  }
`;

const ItemNumber = styled.div`
  font-size: 30px;
  line-height: 40px;
`;

const ItemTitle = styled.div`
  font-size: 30px;
  line-height: 40px;
  padding: 0 24px;
  margin-top: 24px;
  @media (max-width: 500px) {
    padding: 0 16px;
    margin-top: 16px;
    font-size: 28px;
    line-height: 32px;
  }
`;

const SubTitle = styled(ItemTitle)`
  margin-top: 72px;
  margin-left: 12px;
  padding: 0px;
  display: flex;
  align-items: top;
  @media (max-width: 500px) {
    padding: 0px;
    margin-top: 48px;
  }
`;

const ProjectLink = styled.a`
  font-size: 20px;
  line-height: 24px;
  border: 1px solid #17191c;
  color: #e7e7f2;
  padding: 4px 8px;
  border-radius: 200px;
  text-decoration: none;
  underline: none;
  margin-left: 16px;
  @media (max-width: 500px) {
    margin-left: 16px;
  }
  & img {
    height: 14px;
    width: 14px;
  }
  &:hover {
    pointer: cursor;
  }
`;

const Count = styled.span`
  font-size: 14px;
  line-height: 16px;
`;

const ItemDescription = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #a2a2b0;
  padding: 8px 24px 16px 24px;
  @media (max-width: 500px) {
    padding: 8px 16px 16px 16px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  padding: 24px;
  border-bottom: 1px solid #17191c;
  @media (max-width: 500px) {
    padding: 16px;
  }
`;

const Footer = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: #e7e7f2;
  font-family: "RT Dromo Book";
  padding-bottom: 24px;
  z-index: 999 !important;
  background: black;
  @media (max-width: 500px) {
    font-size: 26px;
    margin-top: 64px;
    line-height: 35px;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.invisiCol = React.createRef();
    this.state = {
      entries: null,
      loaded: false,
      visibility: "",
    };
  }

  async componentDidMount() {
    const entries = await client.getEntries({
      content_type: "entry",
      order: "fields.order",
    });
    this.setState({
      entries: entries.items,
      loaded: true,
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        <Wrapper>
          <GlobalStyle />
          <Container fluid={true}>
            <Row gutterWidth={24} className="reverse padded">
              <Col sm={12} md={12} lg={8}>
                <Name src={NameType} />
              </Col>
              <Col sm={12} md={12} lg={4}>
                <Links>
                  <li>
                    <a href="mailto:m@matthewfamularo.com">
                      EMAIL <Icon src={ExternalLink} />
                    </a>
                  </li>
                  <li>
                    <a href="http://instagram.com/matthewfamularo.svg">
                      INSTAGRAM <Icon src={ExternalLink} />
                    </a>
                  </li>
                  <li>
                    <a href="http://letterboxd.com/matthewfamularo">
                      LETTERBOXD <Icon src={ExternalLink} />
                    </a>
                  </li>
                </Links>
              </Col>
            </Row>
            <Row gutterWidth={24} className="padded">
              <Col sm={12} md={12} lg={9}>
                <About>
                  is a designer living in Brooklyn, New York. He can be found
                  vibing with his weenie dog <Rebus imgSrc={marn}>MARNI</Rebus>,
                  drinking <Rebus imgSrc={toms}>Toms Juice</Rebus>, or scouring
                  the internet for old <Rebus imgSrc={bmw}>BMWs</Rebus>.
                </About>
              </Col>
            </Row>
            <Projects>
              {this.state.entries.map((item, index) => (
                <Project>
                  <Row
                    gutterWidth={24}
                    className="padded reverse"
                    align="end"
                    justify="between"
                  >
                    <Col sm={12} md={12} lg={7}>
                      <Title>
                        {item.fields.title}
                        <Duration>{item.fields.duration}</Duration>
                      </Title>
                      <Description>{item.fields.description}</Description>
                    </Col>
                    {item.fields.hero && (
                      <Col sm={12} md={12} lg={4}>
                        <LazyLoadImage
                          effect="blur"
                          visibleByDefault={true}
                          className="heroPic"
                          src={item.fields.hero.fields.file.url}
                        />
                      </Col>
                    )}
                  </Row>
                  {item.fields.carouselItems && (
                    <div>
                      <Row gutterWidth={24} className="padded">
                        <SubTitle>
                          {item.fields.carouselHeading}{" "}
                          <Count>{item.fields.carouselItems.length}</Count>
                        </SubTitle>
                      </Row>
                      <Row gutterWidth={24}>
                        <ScrollContainer
                          hideScrollbars={false}
                          className="carousel"
                        >
                          {item.fields.carouselItems.map((item, index) => (
                            <Item key={index}>
                              <div>
                                <ItemTitle>{item.fields.title} </ItemTitle>
                                <ItemDescription>
                                  {item.fields.description}
                                </ItemDescription>
                                {item.fields.link && (
                                  <ProjectLink
                                    href={item.fields.link}
                                    target="_blank"
                                  >
                                    {item.fields.linkText
                                      ? item.fields.linkText
                                      : "View"}
                                    <Icon src={ExternalLink} />
                                  </ProjectLink>
                                )}
                              </div>
                              <LazyLoadImage
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  overflow: "hidden",
                                }}
                                wrapperClassName="lazy"
                                effect="blur"
                                className="itemPic"
                                src={item.fields.image.fields.file.url}
                              />
                            </Item>
                          ))}
                          <Spacer>{"."}</Spacer>
                        </ScrollContainer>
                      </Row>
                    </div>
                  )}
                </Project>
              ))}
            </Projects>
            <Footer>
              <Row
                gutterWidth={24}
                justify="between"
                align="end"
                className="padded"
              >
                <Col xs={6} sm={2}>
                  <Name src={NameType} />
                </Col>
                <Col sm={12} md={12} lg={6}>
                  ©2025{" "}
                </Col>
                <Col sm={12} md={12} lg={4}>
                  <Links>
                    <li>
                      <a href="mailto:m@matthewfamularo.com">
                        EMAIL <Icon src={ExternalLink} />
                      </a>
                    </li>
                    <li>
                      <a href="http://instagram.com/matthewfamularo.svg">
                        INSTAGRAM <Icon src={ExternalLink} />
                      </a>
                    </li>
                    <li>
                      <a href="http://letterboxd.com/matthewfamularo">
                        LETTERBOXD <Icon src={ExternalLink} />
                      </a>
                    </li>
                  </Links>
                </Col>
              </Row>
            </Footer>
          </Container>
        </Wrapper>
      );
    } else {
      return <div>loading</div>;
    }
  }
}

export default App;
